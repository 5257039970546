@font-face {
  font-family: "KGBlankSpaceSolid";
  src: url("./assets/fonts/KGBlankSpaceSolid.ttf");
}

.noUi-target {
  background: #bbbbbb;
  border: 0;
  box-shadow: none;
}

.noUi-horizontal {
  height: 2px;
}

.noUi-connect {
  background: #faa61a;
  box-shadow: none;
}

.noUi-horizontal .noUi-handle {
  width: 0px;
  height: 0px;
  left: 0px;
  top: -10px;
}
.noUi-handle {
  border: 2px solid #fff;
  border-radius: 50%;
  background: #faa61a;
  cursor: pointer;
  box-shadow: none;
}

.noUi-handle:after,
.noUi-handle:before {
  display: none;
}

.noUi-handle:after,
.noUi-handle:before {
  display: none;
}

.noUi-pips {
  color: #000;
}

.noUi-horizontal .noUi-tooltip {
  -webkit-transform: translate(50%, 0);
  transform: translate(50%, 0);
  left: 00%;
  bottom: 120%;
}

.noUi-value {
  padding-top: 3px;
  font-size: 12px;
}

.noUi-marker-horizontal.noUi-marker {
  width: 1px;
  height: 3px;
}

.noUi-marker-horizontal.noUi-marker-large {
  height: 10px;
}

/* .noUi-tooltip {
  display: none;
}
.noUi-active .noUi-tooltip {
  display: block;
} */

.noUi-horizontal .noUi-handle-lower .noUi-tooltip:before,
.noUi-horizontal .noUi-handle-upper .noUi-tooltip:before {
  content: "";
  width: 24px;
  height: 24px;
  position: absolute;
  left: 50%;

  background: #faa61a;
  margin-left: -12px;
  z-index: -1;
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 10px;
  border-radius: 10px 10px 0 10px;
}

.noUi-tooltip {
  left: 50%;
  top: -36px;
  padding: 2px;
  width: 32px;
  height: 32px;
  margin-left: -2px;
  border: none;
  background: #faa61a;
  color: #fff;
  line-height: 28px;
  border-radius: 50%;
  font-size: 10px;
  font-weight: 400;
}

.noUi-pips-horizontal {
  height: 55px;
}

.apexcharts-legend-series {
  display: none !important;
}
