.container {
  overflow: hidden;
  position: relative;
  width: 100%;
}

.slidingBackground {
  height: 100%;
  width: 5076px;
  background-repeat: repeat-x;
}

.animateRoad {
  animation: slide 5s linear infinite;
}

@keyframes slide {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-1692px, 0, 0);
  }
}
